export default function Rain() {
  return <svg width="20" height="100%" viewBox="0 0 114 114" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="Rain" data-sentry-source-file="Rain.jsx">
    <g clipPath="url(#clip0_263_89333)" data-sentry-element="g" data-sentry-source-file="Rain.jsx">
    <path d="M57 114C88.4802 114 114 88.4802 114 57C114 25.5198 88.4802 0 57 0C25.5198 0 0 25.5198 0 57C0 88.4802 25.5198 114 57 114Z" fill="#FFD54F" data-sentry-element="path" data-sentry-source-file="Rain.jsx" />
    <path d="M57 0C55.8444 0 54.698 0.0385195 53.5593 0.10643C83.4371 1.88568 107.119 26.6753 107.119 57C107.119 87.3247 83.4371 112.114 53.5596 113.894C54.698 113.961 55.8444 114 57 114C88.4803 114 114 88.4803 114 57C114 25.5197 88.4803 0 57 0Z" fill="#FFCA28" data-sentry-element="path" data-sentry-source-file="Rain.jsx" />
    <path d="M57 107.119C43.6128 107.119 31.027 101.906 21.5607 92.4393C12.0945 82.9731 6.88123 70.3872 6.88123 57C6.88123 43.6128 12.0945 31.0269 21.5607 21.5607C31.027 12.0945 43.6128 6.8812 57 6.8812C70.3872 6.8812 82.9731 12.0945 92.4393 21.5607C101.906 31.0269 107.119 43.6128 107.119 57C107.119 70.3872 101.906 82.9731 92.4393 92.4393C82.9731 101.906 70.3872 107.119 57 107.119Z" fill="#FFB300" data-sentry-element="path" data-sentry-source-file="Rain.jsx" />
    <path d="M92.4393 21.5607C82.9731 12.0945 70.3872 6.8812 57 6.8812C55.8465 6.8812 54.6995 6.92261 53.5593 6.99943C65.6511 7.8139 76.9073 12.9101 85.5579 21.5605C95.0242 31.0267 100.237 43.6126 100.237 56.9998C100.237 70.387 95.0242 82.9729 85.5579 92.4391C76.9073 101.09 65.6509 106.186 53.5593 107C54.6995 107.077 55.8465 107.118 57 107.118C70.3872 107.118 82.9731 101.905 92.4393 92.4389C101.906 82.9726 107.119 70.3868 107.119 56.9996C107.119 43.6124 101.906 31.0269 92.4393 21.5607Z" fill="#FFA000" data-sentry-element="path" data-sentry-source-file="Rain.jsx" />
    <path d="M93.6201 20.38C83.8386 10.5982 70.8332 5.21127 57 5.21127C43.1668 5.21127 30.1615 10.5982 20.38 20.38C10.5985 30.1617 5.2113 43.1668 5.2113 57C5.2113 70.8332 10.5982 83.8385 20.3798 93.6201C30.1613 103.402 43.1668 108.789 57 108.789C70.8332 108.789 83.8386 103.402 93.6201 93.6203C103.402 83.8388 108.789 70.8332 108.789 57C108.789 43.1668 103.402 30.1615 93.6201 20.38ZM91.2586 91.2586C82.1076 100.409 69.941 105.449 57 105.449C44.059 105.449 31.8924 100.409 22.7415 91.2586C13.5907 82.1076 8.55115 69.941 8.55115 57C8.55115 44.059 13.5907 31.8924 22.7415 22.7414C31.8924 13.5907 44.059 8.55112 57 8.55112C69.941 8.55112 82.1076 13.5907 91.2586 22.7414C100.409 31.8924 105.449 44.059 105.449 57C105.449 69.941 100.409 82.1076 91.2586 91.2586Z" fill="#FB8C00" data-sentry-element="path" data-sentry-source-file="Rain.jsx" />
    <path d="M60.8493 30.5838C59.4341 27.8821 55.5568 27.9113 54.1825 30.634L41.278 56.1995C35.0754 68.4877 44.0064 83 57.7713 83C71.6583 83 80.581 68.2535 74.1374 55.952L60.8493 30.5838Z" fill="#F9D761" stroke="#FFA000" strokeWidth="4" data-sentry-element="path" data-sentry-source-file="Rain.jsx" />
    <path d="M56.2812 32.8546C55.6494 30.2291 58.2236 29.6887 59.4898 32.0738L72.1449 55.9129C75.3982 62.0413 74.7178 69.514 70.411 74.954L69.6895 75.8654C68.5765 77.2713 67.1683 78.4156 65.5644 79.2176C64.5265 79.7366 63.4165 80.1483 62.2576 80.0873C59.5224 79.9432 55.1078 78.3114 61 68C67.3146 56.9495 58.7546 43.1333 56.2812 32.8546Z" fill="#F7CC46" data-sentry-element="path" data-sentry-source-file="Rain.jsx" />
    <path d="M81.895 85H70.42L57.84 63.835H47.98V85H38.035V28.305H62.94C68.4933 28.305 72.97 29.9483 76.37 33.235C79.77 36.5217 81.47 40.8 81.47 46.07C81.47 50.83 80.1667 54.655 77.56 57.545C74.9533 60.3783 71.78 62.1067 68.04 62.73L81.895 85ZM61.58 55.08C64.4133 55.08 66.7367 54.2583 68.55 52.615C70.3633 50.9717 71.27 48.79 71.27 46.07C71.27 43.35 70.3633 41.1683 68.55 39.525C66.7367 37.8817 64.4133 37.06 61.58 37.06H47.98V55.08H61.58Z" fill="#A95100" data-sentry-element="path" data-sentry-source-file="Rain.jsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="Rain.jsx">
    <clipPath id="clip0_263_89333" data-sentry-element="clipPath" data-sentry-source-file="Rain.jsx">
    <rect width="114" height="114" fill="white" data-sentry-element="rect" data-sentry-source-file="Rain.jsx" />
    </clipPath>
    </defs>
    </svg>;
}